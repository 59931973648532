import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';

// components
import {
  Layout,
  Section,
  Seo,
  SocialShare,
  Title,
  SubTitle,
  LinkList,
  LinkItem,
  StyledLink,
  Button,
} from '@/components';

// styled components for posts
import {
  FeaturedImageWrap,
  Meta,
  Row,
  Body,
  Time,
  ContentWrap,
  Divider,
  Wrapper,
} from '../components/posts/styles';

/**
 * The template for post pages
 *
 * Automatically generated for each wpPost sourced from WP
 */
const PostTemplate = ({ data }: PageProps<Queries.PostQuery>) => {
  const post = data.wpPost;

  const { title, uri, excerpt } = post || {};
  const previewImg = post?.featuredImage
    ? post?.featuredImage?.node?.localFile?.childImageSharp?.resize
    : null;

  const seoTitle = `${title} | ${data?.site?.siteMetadata?.title}`;

  // process description to remove wp-infused markup
  const description =
    excerpt
      ?.replace(/(<\/*p>|\[&hellip;\])/gi, '')
      .trim()
      .concat('...') || '';

  const filteredCategoryNodes =
    data.wpPost?.categories?.nodes?.filter(
      (node) => node && node?.name && node?.name !== 'Uncategorized'
    ) || [];

  const categoryContent = filteredCategoryNodes.length
    ? filteredCategoryNodes.map((node) =>
        node && node?.uri ? (
          <LinkItem key={node.id}>
            <StyledLink
              color="primary"
              hoverColor="primary-hover"
              to={node.uri}
            >
              {node.name}
            </StyledLink>
          </LinkItem>
        ) : null
      )
    : null;

  const tags = data.wpPost?.tags?.nodes || [];

  const tagContent = tags.map((node) =>
    node ? (
      <LinkItem key={node.id}>
        <Button to={node.uri || ''}>{node.name}</Button>
      </LinkItem>
    ) : null
  );

  const imageData =
    data.wpPost?.featuredImage?.node?.localFile?.childImageSharp
      ?.gatsbyImageData;

  return (
    <Layout withSidebar>
      <Seo
        title={seoTitle}
        pathname={uri}
        article
        description={description}
        image={previewImg ? { ...previewImg } : undefined}
      />
      <Section>
        <Wrapper as="article">
          <header>
            {imageData && (
              <FeaturedImageWrap>
                <GatsbyImage
                  image={imageData}
                  alt={data.wpPost?.featuredImage.node.altText || ''}
                />
              </FeaturedImageWrap>
            )}
            <Row>
              <Title>{title}</Title>
              {data.wpPost?.date && (
                <Time dateTime={data.wpPost.date}>
                  Posted on {format(new Date(data.wpPost.date), 'MMMM d, yyyy')}
                </Time>
              )}
            </Row>
          </header>
          <Row>
            <ContentWrap>
              {data.wpPost?.content && (
                <Body
                  dangerouslySetInnerHTML={{ __html: data.wpPost?.content }}
                />
              )}
              <Divider />
              {data.wpPost?.prayer?.prayer && (
                <>
                  <section>
                    <SubTitle>Prayer</SubTitle>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.wpPost?.prayer?.prayer,
                      }}
                    />
                  </section>
                  <Divider />
                </>
              )}
            </ContentWrap>
          </Row>
          <footer>
            <Meta>
              <ContentWrap>
                {filteredCategoryNodes.length > 0 ? (
                  <LinkList label="Category">{categoryContent}</LinkList>
                ) : null}
                {tags.length > 0 ? (
                  <LinkList label="Tags">{tagContent}</LinkList>
                ) : null}
                <SocialShare title={title} link={uri} />
              </ContentWrap>
            </Meta>
          </footer>
        </Wrapper>
      </Section>
    </Layout>
  );
};

export default PostTemplate;

// page query
export const query = graphql`
  query Post($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      uri
      excerpt
      content
      date
      title
      tags {
        nodes {
          id
          name
          uri
        }
      }
      prayer {
        prayer
      }
      uri
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1700)
              resize(width: 1280, quality: 50) {
                src
                width
                height
              }
            }
          }
        }
      }
      categories {
        nodes {
          id
          uri
          name
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
